.login {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    &__image {
        width: 100%;
        flex: 0 75%;
        max-width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;

        .slick-slider {
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            width: auto;
            max-width: 100%;
            margin: 0 auto;
            height: 80vh;
        }
    }

    .login--form {
        width: 100%;
        flex: 0 25%;
        max-width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 20px;
        border-left: solid 1px #ddd;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    }

    &__title {
        text-align: center;
        font-size: 35px;
        margin-bottom: 20px;
    }

    &__form {
        width: 100%;
        flex: 0 90%;
        max-width: 90%;

        &__action {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__submit {
            .login-form-button {
                width: 100%;
            }
        }
    }
}

.login-loading{
    max-height: 100% !important;
}