.navbar-default {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    background: linear-gradient(180deg, #001841 0%, #01A0E9 100%);
    height: 100%;
    overflow-y: auto;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;
    z-index: 10;
    ul {
        padding: 10px 5px;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: auto;
        height: 100%;
        align-items: center;
        li {
            margin-bottom: 30px;
        }
        li.active {
            a {
                font-weight: 600;
                text-transform: uppercase;
                &:after {
                    width: 100%;
                    @include transition;
                }
            }
        }
        a {
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            height: 100%;
            display: flex;
            margin: auto;
            justify-content: center;
            align-items: center;
            padding: 0 5px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: #fff;
                @include transition;
            }
            &:hover {
                &:after {
                    width: 100%;
                    @include transition;
                }
            }
        }
    }
}
