.dashboard-counter {
    background-color: #f0f2f5;
    padding: 15px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item-counter {
        padding: 15px !important;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        width: calc((100% / 3) - 30px);
        min-width: 200px;
        flex: 1;
        margin: 15px;
        min-height: 100px;
    }
    .counter-header {
        label {
            font-weight: 500;
            font-size: 1.2rem;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .counter-content {
        display: flex;
        flex-direction: column;
        span {
            color: #888;
            strong {
                color: black;
            }
        }
    }
}

.user-detail-page {
    margin: -15px;
    padding-top: 52px;
    .ant-collapse-header {
        display: flex;
        align-items: center;
        .ant-collapse-header-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 15px;
            label {
                font-weight: bold;
            }
            .percent {
                background-color: #8ca9fb;
                padding: 2px 10px;
                border-radius: 20px;
                color: #fff;
            }
        }
    }
    .ant-collapse-content {
        background-color: #f0f2f5;
        .ant-collapse-content-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 0;
        }
        .ant-collapse-content-item {
            width: calc(100% - 50px);
            .content-item-group {
                background-color: #04721d;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                border-radius: 5px;
                padding: 15px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-wrap: wrap;
                font-weight: 500;
                margin: 10px 0;
            }
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td,
            .ant-table tfoot > tr > th,
            .ant-table tfoot > tr > td {
                padding: 10px !important;
            }
        }
    }
}
