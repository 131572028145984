.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.h-full {
    height: 100vh;
}
.container {
    max-width: 1366px;
    margin: auto;
}
.mt-3 {
    margin-top: 15px;
}
.pt-0 {
    padding-top: 0 !important;
}
.mb-3 {
    margin-bottom: 15px;
}
.mr-3 {
    margin-right: 15px;
}
.blocked {
    opacity: 0.5;
}
.hidden {
    display: none;
}
.pd15 {
    padding: 15px;
}
.pd10 {
    padding: 10px;
}
.action-bar {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 10;
}

.flex {
    display: flex;
}
.align-center{
    align-items: center;
}

.cursor-pointer{
    cursor: pointer;
}