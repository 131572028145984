.content {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;

    &__filter,
    &__pagination {
        flex-shrink: 0;
    }

    &__body {
        height: 100%;
        flex: 1;
        // .ant-tabs-top,
        // .ant-tabs-content-top {
        //     height: 100%;
        //     .content {
        //         padding: unset;
        //     }
        // }
    }

    &__form {
        flex: 1;
        overflow: auto;
    }

    &__header {
        display: flex;
        grid-gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__action {
        padding-bottom: 1rem;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        .mr-3 {
            margin-right: 1rem;
        }
    }

    &__filter {
        flex: 1;
        padding-bottom: 1rem;
    }

    &__pagination {
        padding-top: 1rem;
    }

    &__table {
        flex: 1;
        height: 100%;
        width: 100%;
    }
}

.component {
    &__user {
        max-width: 250px;
        padding-top: 10px;
        .ant-descriptions-row {
            .ant-descriptions-item {
                padding-bottom: 0.4rem;
            }
            &:last-child {
                .ant-descriptions-item {
                    padding-bottom: unset;
                }
            }
        }
    }
}

.p-0 {
    padding: 0 !important;
}
