body {
    padding: unset;
    margin: unset;
    box-sizing: border-box;
}
.app__layout {
    margin-left: 200px;
    padding: 15px;
    width: calc(100vw - 200px);
}
@import "variables";
@import "global";
@import "mixins";
@import "pages/login";

@import "./components/content";
@import "./components/counter";
@import "./partials/menu.scss";

@import "./components/lucky";

.w-100 .ant-space-item {
    width: 100%;
}
